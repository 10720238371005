






import { Component, Vue, Watch,Prop } from "vue-property-decorator"
import goodsOne from "./goodsOne.vue"

@Component({
	components: {
		goodsOne
	}
})
export default class Default extends Vue {
	@Prop(Array)goodsListData!:Array<any>
	@Watch("name")
	getWatchValue(newVal: string, oldVal: string) {
		console.log(newVal, oldVal)
	}


	toGoodsDetails(item: any){
    this.$router.push({
      path:`/goodsDetails/${item.id}`,
      query:{
        priceShowStyle:item.priceShowStyle
      }
    })
	}

}
